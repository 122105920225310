.PaymentRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--light-grey);
  padding-left: 1rem;
  color: var(--dark-grey);
}
.PaymentRow:first-child {
  border-top: 1px solid var(--light-grey);
}
/* .PaymentRow:hover {
  background-color: rgb(252, 252, 252);
} */

.PaymentRow .avatar {
  width: 4rem;
  height: 4rem;
  border-radius: 4rem;
  margin-right: 1rem;
}
.PaymentRow > .time {
  width: 7.5rem;
  font-weight: lighter;
  padding-top: 0.75rem;
}
.PaymentRow > .actions {
  flex-direction: row;
  align-self: center;
  padding-left: 1rem;
}
.PaymentRow > .payer {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 20rem;
}
.PaymentRow > .payer > .links {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PaymentRow > .payees {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 2;
  gap: 0.5rem;
}
.PaymentRow > .payees > .payee {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.PaymentRow > .payees > .payee > .avatar {
  margin-left: 2rem;
}
.PaymentRow > .payees > .payee > .arrow {
  min-width: 12rem;
}
.PaymentRow > .payees > .payee > .status {
  flex-grow: 2;
  text-align: end;
  padding-right: 2rem;
  font-weight: normal;
  color: var(--blue);
}
.PaymentRow > .payees > .payee > .green {
  color: var(--green);
}
.PaymentRow > .payees > .payee > .red {
  color: var(--red);
}
