.BusinessSelector {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.BusinessSelector > .loading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}
.BusinessSelector > .business {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.BusinessSelector > .business > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  font-weight: normal;
}
.BusinessSelector .avatar {
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  margin-left: 1rem;
}
