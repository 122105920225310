.UserDevices {
  width: 100%;
}
.UserDevices > .backlink {
  font-size: 90%;
  margin-bottom: 1rem;
}
.UserDevices .List .title {
  text-transform: capitalize;
}
.UserDevices .deviceIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 3rem;
  background-color: var(--grey);
  color: var(--primary);
  margin-right: 1.5rem;
}
.UserDevices .deviceIcon.disabled {
  color: var(--dark-grey);
}
