.EntityPage {
  width: 100%;
}

.EntityPage > .backlink {
  font-size: 90%;
  margin-bottom: 1rem;
}

.EntityPage > h3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.EntityPage > h3 > img {
  margin-right: 1rem;
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 4.5rem;
}

.EntityPage > h3 > a.titleLink {
  color: var(--blue);
  font-size: 75%;
  padding: 0 0.5rem;
}

.EntityPage > table {
  border: 1px dashed var(--primary-light);
  border-radius: 0.5rem;
  width: auto;
  margin-bottom: 2rem;
}
.EntityPage > table td {
  padding: 0.25rem 1.5rem;
  padding-right: 3rem;
  border: none;
  vertical-align: top;
}
.EntityPage > table td:last-child {
  padding-right: 1.5rem;
}
.EntityPage > table td:first-child {
  text-align: right;
}
.EntityPage > table tr:last-child td {
  padding-bottom: 1rem;
}
.EntityPage > table td span {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.EntityPage > table input,
.EntityPage > table select {
  margin-bottom: 0;
  padding: 0.25rem 1rem;
  height: auto;
  font-size: 90%;
}
.EntityPage > table input.inline {
  display: inline;
  width: 5rem;
}
.EntityPage > table select.inline {
  display: inline;
  width: 10rem;
}
.EntityPage > table input:invalid {
  background-color: #ffb89c;
}
.EntityPage > table .buttonRow {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 1rem;
}
.EntityPage > table .buttonRow button {
  width: 100%;
}

.EntityPage > table > tr > td > table {
  width: auto;
}

.EntityPage > table > tr > td > ul {
  margin-bottom: 0;
}

.EntityPage > table > tr > td > ul:last-child {
  margin-bottom: 0.5rem;
}
