.BankAccountSelector {
  display: flex;
  flex-direction: column;
  position: relative;
}
.BankAccountSelector .inlineLoader {
  position: absolute;
  z-index: 99;
}
.BankAccountSelector .badge {
  position: absolute;
  z-index: 99;
  top: 0.2rem;
  right: 0.2rem;
  background-color: var(--blue);
  color: var(--white);
  border-radius: 2rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.BankAccountSelector .accountNumber {
  font-size: 80%;
}

.BankAccountSelector .avatar {
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  margin-left: 1rem;
}

.BankAccountSelector .checkbox-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.BankAccountSelector .checkbox-container input[type="checkbox"] {
  margin-right: 10px;
}

.BankAccountSelector .checkbox-label {
  /* order: 2;  */
  margin-bottom: 0;
  /* font-size: 80%; */
  cursor: pointer;
}

