.UserSelector {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.UserSelector > .user {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.UserSelector > .user > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  font-weight: normal;
}
.UserSelector .avatar {
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  margin-left: 1rem;
}
