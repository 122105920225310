.BusinessPaymentLink {
  width: 100%;
}

.BusinessPaymentLink>.backlink {
  font-size: 90%;
  margin-bottom: 1rem;
}

.BusinessPaymentLink > h3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 3rem;
}
.BusinessPaymentLink > h3 > a.titleLink {
  color: var(--blue);
  font-size: 75%;
  padding: 0 0.5rem;
}

.BusinessPaymentLink>table {
  border: 1px dashed var(--primary-light);
  border-radius: 0.5rem;
  width: auto;
  margin-bottom: 2rem;
}
.BusinessPaymentLink>table td {
  padding: 0.25rem 1.5rem;
  padding-right: 3rem;
  border: none;
  vertical-align: top;
}
.BusinessPaymentLink>table td:last-child {
  padding-right: 1.5rem;
}
.BusinessPaymentLink>table td:first-child {
  text-align: right;
}
.BusinessPaymentLink>table tr:last-child td {
  padding-bottom: 1rem;
}
.BusinessPaymentLink>table td span {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.BusinessPaymentLink>table input, .BusinessPaymentLink>table select {
  margin-bottom: 0;
  padding: 0.25rem 1rem;
  height: auto;
  font-size: 90%;
}
.BusinessPaymentLink>table input.inline {
  display: inline;
  width: 5rem;
}
.BusinessPaymentLink>table select.inline {
  display: inline;
  width: 10rem;
}
.BusinessPaymentLink>table input:invalid {
  background-color: #ffb89c;
}
.BusinessPaymentLink>table .buttonRow {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 1rem;
}
.BusinessPaymentLink>table .buttonRow button {
  width: 100%;
}
