.ConstantsPage {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.ConstantsPage section {
  border: 1px solid var(--grey);
  border-left: 0.5rem solid var(--blue);
  border-radius: 3px;
  padding: 1rem 2rem;
}
