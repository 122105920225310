.Sandbox {
  width: 100%;
}

.Sandbox > .info,
.Sandbox > .error {
  color: var(--black) !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  font-family: monospace;
  white-space: pre;
}

.Sandbox > .row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  margin-left: 0;
}
.Sandbox > .row > h4 {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 4rem;
}
.Sandbox > .row > button {
  margin-left: 1rem;
  margin-bottom: 0;
  height: 3rem;
  line-height: 2.75rem;
  padding: 0 1rem;
}

.Sandbox .linkRow {
  display: flex;
  flex-direction: row;
}
.Sandbox .SandboxTippy {
  background-color: var(--white);
  border: 1px solid var(--grey);
}
.Sandbox .SandboxTippy .tippy-arrow::before {
  color: var(--grey);
  border-width: 0 7px 7px;
}
.Sandbox .SandboxTippy .tippy-content {
  padding: 1rem 0;
}
.Sandbox .SandboxTippy .tippy-content div {
  display: flex;
  flex-direction: column;
}
.Sandbox .SandboxTippy .tippyLink,
.Sandbox .SandboxTippy a {
  padding: 0.5rem 1rem;
  color: var(--black);
  width: 100%;
  cursor: pointer;
}
.Sandbox .SandboxTippy .tippyLink:hover,
.Sandbox .SandboxTippy a:hover {
  background-color: var(--light-grey);
  border-radius: 3px;
}

.Sandbox .editor {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 1px solid var(--light-grey);
  border-radius: 3px;
}

.Sandbox .flexButton {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Sandbox .flexButton .Loader {
  margin-top: -0.5rem;
}

.Sandbox .infoResult {
  display: flex;
  width: 100%;
  border: 1px solid rgb(52, 161, 195);
  border-radius: 4px;
  background-color: rgb(228, 249, 252);
  padding: 0.5rem 1rem;
  margin-bottom: 2rem;
  margin-top: 1rem;
  color: var(--black);
  flex-direction: column;
  align-items: flex-start;
  font-family: monospace;
  white-space: pre;
}
.Sandbox .errorResult {
  display: flex;
  width: 100%;
  border: 1px solid rgb(195, 52, 71);
  border-radius: 4px;
  background-color: rgb(252, 228, 232);
  padding: 0.5rem 1rem;
  margin-bottom: 2rem;
  color: var(--black);
  flex-direction: column;
  align-items: flex-start;
  font-family: monospace;
  white-space: pre;
}

.Sandbox .layoutSwitch {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-grow: 1;
  margin-right: 1rem;
  font-size: 120%;
}
.Sandbox .layout_COLUMN {
  display: flex;
  flex-direction: column;
}
.Sandbox .layout_ROW {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.Sandbox .layout_ROW > div {
  display: flex;
  flex-direction: column;
  width: 50%;
}
