.PartiesModal.modal_overlay {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  padding: 2rem;
  padding-top: 5rem;
  overflow-y: auto;
  --webkit-overflow-scrolling: touch;
  z-index: 20;
}

.PartiesModal.modal {
  position: absolute;
  min-width: 30rem;
  border: 1px solid var(--grey);
  background: var(--white);
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

.PartiesModal.modal .wide {
  width: 100%;
}

.PartiesModal .red {
  color: var(--red);
}

.PartiesModal .buttonRow {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding-top: 2rem;
}
.PartiesModal button.red {
  color: var(--white);
  background-color: var(--red-dark);
  border-color: var(--red-dark);
}
.PartiesModal button.red:hover {
  color: var(--white);
  background-color: var(--red-dark);
  border-color: var(--red-dark);
  opacity: 0.8;
}

.PartiesModal b {
  font-weight: normal;
}
.PartiesModal .attributeList {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
}

.PartiesModal .attributeList > span {
  font-size: 80%;
  font-weight: normal;
  padding: 0.5rem;
  color: var(--white);
  background-color: var(--primary-light);
  border-radius: 3px;
}

.infoTable tr:first-child > td {
  border-top: 0.1rem solid #e1e1e1;
}

.propertyTable {
  margin: 0;
  table-layout: auto;
}

.propertyTable > tr > td {
  font-size: 100%;
  border-bottom: 0.1rem solid #f0f0f0 !important;
  padding: 1rem 1.2rem;
}

.propertyTable > tr:first-child > td {
  border-top: 0 !important;
}

.propertyTable > tr:last-child > td {
  border-bottom: 0 !important;
}

.propertyTable > tr > td:nth-child(3) {
  text-align: right;
}
