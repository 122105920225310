.TenantPage {
  width: 100%;
}
.TenantPage > h3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 3rem;
}
.TenantPage > h3 > img.avatar {
  width: 4rem;
  height: 4rem;
  border-radius: 4rem;
  margin-right: 1rem;
  border: 1px solid var(--light-grey);
}
.TenantPage > h3 > .tag {
  font-family: monospace;
  margin-left: 1.5rem;
  margin-top: 0.5rem;
  color: var(--dark);
  background-color: var(--light-grey);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-size: 70%;
}
.TenantPage > h3 > a.titleLink {
  color: var(--blue);
  font-size: 75%;
  padding: 0 0.5rem;
}
.TenantPage > .subtitle {
  margin-bottom: 1rem;
}
.TenantPage > .warning {
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  border: 1px dashed var(--orange);
  background-color: rgb(255, 243, 204);
  border-radius: 0.5rem;
  color: var(--orange);
  margin-bottom: 3rem;
  width: 100%;
}
.TenantPage > .warning > span {
  color: var(--dark-grey);
}
.TenantPage > table {
  border: 1px dashed var(--primary-light);
  border-radius: 0.5rem;
  width: auto;
  margin-bottom: 1rem;
}
.TenantPage > table td {
  padding: 0.25rem 1.5rem;
  padding-right: 3rem;
  border: none;
  vertical-align: top;
}
.TenantPage > table td:last-child {
  padding-right: 1.5rem;
}
.TenantPage > table td:first-child {
  text-align: right;
}
.TenantPage > table tr:last-child td {
  padding-bottom: 1rem;
}
.TenantPage > table td span {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.TenantPage .green {
  color: var(--green);
}
.TenantPage .grey {
  color: var(--grey);
}
.TenantPage .dark-grey {
  color: var(--dark-grey);
}

.TenantPage > .linklist.vertical {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 0.5rem;
}
.TenantPage > .linklist.vertical > a {
  margin: 0.25rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.TenantPage > .linklist.vertical > a > .todo {
  font-size: 80%;
  color: var(--red);
  margin-left: 1.5rem;
}
.TenantPage > .linklist.vertical > a > .icon {
  margin-right: 1rem;
}

.TenantPage .buttonRow {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
