.PaymentScheduledRow {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--light-grey);
  padding-left: 1rem;
  color: var(--dark-grey);
}
.PaymentScheduledRow:first-child {
  border-top: 1px solid var(--light-grey);
}
/* .PaymentRequiringVerificationRow:hover {
  background-color: rgb(252, 252, 252);
} */

.PaymentScheduledRow > .titleRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  color: var(--dark-grey);
}

.PaymentScheduledRow > .titleRow .avatar {
  width: 4rem;
  height: 4rem;
  border-radius: 4rem;
  margin-right: 1rem;
}
.PaymentScheduledRow > .titleRow > .time {
  width: 7.5rem;
  font-weight: lighter;
  padding-top: 0.75rem;
}
.PaymentScheduledRow > .titleRow > .payer {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 20rem;
}

.PaymentScheduledRow > .titleRow > .payee {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
}
.PaymentScheduledRow > .titleRow > .payee > .avatar {
  margin-left: 2rem;
}
.PaymentScheduledRow > .titleRow > .payee > .arrow {
  min-width: 12rem;
}
.PaymentScheduledRow > .titleRow > .payee > .status {
  flex-grow: 2;
  text-align: end;
  padding-right: 2rem;
  font-weight: normal;
}
.PaymentScheduledRow > .titleRow > .payee > .status.green {
  color: var(--green);
}
.PaymentScheduledRow > .titleRow > .payee > .status.yellow {
  color: var(--orange);
}
.PaymentScheduledRow > .titleRow > .payee > .status.red {
  color: var(--red);
}

.PaymentScheduledRow > .subtitleRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 8.5rem;
  padding-right: 2rem;
}
.PaymentScheduledRow > .subtitleRow > .info {
  font-size: 80%;
}
.PaymentScheduledRow > .subtitleRow > .actions {
  margin-top: -0.5rem;
  font-size: 80%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
