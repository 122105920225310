.UserTransactions {
  width: 100%;
}

.UserTransactions > .backlink {
  font-size: 90%;
  margin-bottom: 1rem;
}

.UserTransactions .support {
  display: inline-block;
  color: var(--blue);
  font-size: 80%;
}
