.List {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.List>.row {
  display: flex;
  flex-direction: row;
  align-items: center;

  border-top: 1px solid var(--light-grey);
  width: 100%;
  color: var(--dark-grey);
  border-left: 0.5rem solid transparent;
}
.List>.row:last-child {
  border-bottom: 1px solid var(--light-grey);
}
.List>.row.highlight {
  border-left-color: var(--primary-light);
}
.List>.row>.rowWrapper {
  display: flex;
  flex-direction: row;
  /* Put logo in the middle. This looks better when the row is short */
  align-items: center;
  
  padding: 1rem 1rem;
  width: 100%;
  color: var(--dark-grey);
}

.List>.row>a.rowWrapper:hover {
  cursor: pointer;
  background-color: rgb(252, 252, 252);
}
.List>.row>.rowWrapper>img {
  width: 4rem;
  height: 4rem;
  border-radius: 4rem;
  margin-right: 1rem;
  border: 1px solid var(--light-grey);
}

.List>.row>.rowWrapper>.content {
  display: flex;
  flex-direction: column;
  flex-grow: 2;  
}

.List>.row>.rowWrapper>.content>.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
}

.List>.row>.rowWrapper>.content>.subtitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 90%;
}
