.SearchBankAccountsPage {
  width: 100%;
}

.SearchBankAccountsPage .titleRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
}
.SearchBankAccountsPage .titleRow * {
  margin-bottom: 0;
}
.SearchBankAccountsPage .visibilitySelector {
  width: auto;
}
.SearchBankAccountsPage .statusIcon {
  font-size: 120%;
  margin-left: 1rem;
}
.SearchBankAccountsPage .statusIcon.green {
  color: var(--green);
}
.SearchBankAccountsPage .statusIcon.blue {
  color: var(--blue);
}
.SearchBankAccountsPage .statusIcon.red {
  color: var(--red);
}
.SearchBankAccountsPage .statusIcon.orange {
  color: var(--orange);
}
