.UserAccounts {
  width: 100%;
}
.UserAccounts>.backlink {
  font-size: 90%;
  margin-bottom: 1rem;
}
.UserAccounts .titleRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
}
.UserAccounts .titleRow * {
  margin-bottom: 0;
}
.UserAccounts .buttonRow {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.UserAccounts .buttonRow button {
  font-size: 60%;
  padding: 0 2rem;
  height: 3rem;
  line-height: 3rem;
}
.UserAccounts a.menuIcon {
  font-size: 120%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
}
.UserAccounts a.menuIcon:hover {
  background-color: var(--light-grey);
}

.UserAccounts .AccountItemTippy {
  background-color: var(--white);
  border: 1px solid var(--grey);
}
.UserAccounts .AccountItemTippy .tippy-arrow::before {
  border-width: 0 7px 7px;
  color: var(--grey);
}
.UserAccounts .AccountItemTippy .tippy-content {
  padding: 1rem 0;
  min-width: 5rem;
}
.UserAccounts .AccountItemTippy .tippy-content div {
  display: flex;
  flex-direction: column;
}
.UserAccounts .AccountItemTippy .tippyLink,
.UserAccounts .AccountItemTippy a {
  padding: 0.5rem 1rem;
  color: var(--black);
  width: 100%;
  cursor: pointer;
}
.UserAccounts .AccountItemTippy .tippyLink:hover,
.UserAccounts .AccountItemTippy a:hover {
  background-color: var(--light-grey);
  border-radius: 3px;
}
