.EmailList {
  width: 100%;
}
.EmailList > .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1rem 0;
}
.EmailList > .header > h3 {
  margin-bottom: 0;
}
.EmailList > .header > .statusSelector {
  width: auto;
  margin-bottom: 0;
}
.EmailList > .linkRow {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-bottom: 2rem;
}
.EmailList .toggleButton {
  font-size: 150%;
}

