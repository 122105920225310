.ChatMessagesRoot {
  width: 100%;
  height: 65vh;
  border-radius: 1rem 1rem 0 0;

  background-color: var(--white);
  background-image:
    url("data:image/svg+xml,%3Csvg width='64' height='64' viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 16c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm0-2c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6zm33.414-6l5.95-5.95L45.95.636 40 6.586 34.05.636 32.636 2.05 38.586 8l-5.95 5.95 1.414 1.414L40 9.414l5.95 5.95 1.414-1.414L41.414 8zM40 48c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm0-2c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6zM9.414 40l5.95-5.95-1.414-1.414L8 38.586l-5.95-5.95L.636 34.05 6.586 40l-5.95 5.95 1.414 1.414L8 41.414l5.95 5.95 1.414-1.414L9.414 40z' fill='%23f0f0f0' fill-opacity='1' fill-rule='evenodd'/%3E%3C/svg%3E");
}
.ChatMessages {
  width: 100%;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.ChatMessagesRoot .Hidden{
  display: none;
}


.ChatMessages .message {
  padding: 1rem 1.5rem;
  border-radius: 3rem;
  margin-top: 0.5rem;
}
.ChatMessages .message.me {
  background-color: var(--blue);
  border: 1px solid var(--blue);
  color: var(--white);
  align-self: flex-start;
}
.ChatMessages .message.otherUser {
  background-color: var(--light-grey);
  border: 1px solid var(--grey);
  color: var(--black);
  align-self: flex-end;
}
