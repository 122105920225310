.EditBusiness {
  width: 100%;
}

.EditBusiness .titleRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
}
.EditBusiness .titleRow * {
  margin-bottom: 0;
}
