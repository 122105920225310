.SmallUserSearch .results a  {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  
}
.SmallUserSearch .results a .avatar {
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
}
