.NavLink {
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 1rem;
  color: var(--white);
  font-weight: 400;
}

.NavLink:hover {
  color: var(--primary);
  border-color: var(--primary);
}
.NavLink:focus {
  color: var(--primary);
  border-color: var(--primary);
}
