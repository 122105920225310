.BusinessAccounts {
  width: 100%;
}
.BusinessAccounts>.backlink {
  font-size: 90%;
  margin-bottom: 1rem;
}
.BusinessAccounts .titleRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
}
.BusinessAccounts .titleRow * {
  margin-bottom: 0;
}
.BusinessAccounts .account {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem;
  border-top: 1px solid var(--light-grey);
}
.BusinessAccounts .account:last-child {
  border-bottom: 1px solid var(--light-grey);
}
.BusinessAccounts .account .title {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  color: var(--black);
  font-size: 110%;
  font-weight: normal;
}
.BusinessAccounts .account .title .avatar {
  width: 3rem;
  height: 3rem;
  border-radius: 3rem;
}
.BusinessAccounts .account .spacer {
  flex-grow: 2;
}
.BusinessAccounts .account .badge {
   width: 2.5rem;
   height: 2.5rem;
   border-radius: 2.5rem;
   border: 1px dotted var(--light-grey);
}
.BusinessAccounts .account .badge.akahu {
  background-color: #8232ff;
}
.BusinessAccounts .account .badge.manual {
   color: var(--white);
   background-color: var(--blue);
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 80%;
   font-weight: bold;
   cursor: default;
}
.BusinessAccounts .account .subtitle {
  color: var(--dark-grey);;
  font-weight: lighter;
}
.BusinessAccounts .account b {
  font-weight: normal;
}
.BusinessAccounts .account u {
  text-decoration: none;
  border-bottom: 1px dashed var(--grey);
  line-height: normal;
}
.BusinessAccounts .account .meta {
  margin-left: 4rem;
  display: flex;
  flex-direction: column;
}
.BusinessAccounts .account .meta>div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
.BusinessAccounts .account .meta .smallRow {
  color: var(--grey);
  font-size: 80%;
}

.BusinessTippy pre {
  border: none;
  background: none;
  padding: 1rem;
  margin: 0;
}
