.UserPage {
  width: 100%;
}
.UserPage > h3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 3rem;
}
.UserPage > h3 > img.avatar {
  width: 6rem;
  height: 6rem;
  border-radius: 6rem;
  margin-right: 1rem;
  border: 1px solid var(--light-grey);
}
.UserPage > h3 > .tag {
  font-family: monospace;
  margin-left: 1.5rem;
  /* margin-top: 0.5rem; */
  color: var(--black);
  background-color: var(--light-grey);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-size: 70%;
}
.UserPage > h3 > a.titleLink {
  color: var(--blue);
  font-size: 75%;
  padding: 0 0.5rem;
}
.UserPage > .subtitle {
  margin-bottom: 1rem;
}
.UserPage > .linklist.vertical {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.UserPage > .linklist.vertical > a {
  margin: 0.25rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.UserPage > .linklist.vertical > a > .todo {
  font-size: 80%;
  color: var(--red);
  margin-left: 1.5rem;
}
.UserPage > .linklist.vertical > a > .icon {
  margin-right: 1rem;
}
.UserPage .buttonRow {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.UserPage .green {
  color: var(--green);
}
.UserPage .grey {
  color: var(--grey);
}
.UserPage .dark-grey {
  color: var(--dark-grey);
}
