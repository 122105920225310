div.description > div {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  text-align: center;
  margin-left: 1rem;
  margin-bottom: 1rem;
}

img.avatar {
  width: 3rem;
  height: 3rem;
  border-radius: 3rem;
  margin-right: 1rem;
}
