.EmailForm {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1rem;
  gap: 1rem;
}
.EmailForm label {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.EmailForm label>span.text {
  font-weight: 300;
  font-size: 80%;
  background-color: rgb(252, 252, 252);
  border-left: 2px solid var(--light-grey);
  padding: 0 0.5rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.EmailForm label>span.text a {
  font-weight: normal;
}
.EmailForm label>span.text a.selected {
  color: var(--dark-grey);
  cursor: default;
  text-decoration: underline;
  font-weight: normal;
}
.EmailForm .SmallSearch {
  margin-bottom: 0;
}
.EmailForm input, .EmailForm select {
  margin-bottom: 0;
  padding: 0 0.5rem;
  height: 2.5rem;
  font-size: 90%;
}
.EmailForm .inlineLoader {
  top: 0.25rem;
  right: 0.5rem;
}
.EmailForm select {
  text-overflow: clip;
  padding-right: 2.5rem;
}
.EmailForm .dateWarning {
  font-size: 80%;
  border: 2px dashed var(--orange);
  padding: 0.5rem
}
