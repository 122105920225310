.Login {
  width: 20rem;
  margin: 5rem auto;
}

.Login .error {
  font-size: 80%;
  color: var(--red);
}

.Login input {
  width: 100%;
}
