.UserEmail {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  position: relative;
}

.UserEmail > .sidebar {
  display: flex;
  flex-direction: column;
  width: 40rem;
  min-width: 20rem;
  border: 1px solid var(--light-grey);
  border-radius: 3px;
  position: sticky;
  height: min-content;
  top: 1rem;
  background-color: var(--white);
}

.UserEmail > .mainCol {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  border: 1px solid var(--light-grey);
  padding-top: 1.5rem;
  border-radius: 3px;
}

.UserEmail > .mainCol > h3 {
  padding: 0 1rem;
}
.UserEmail > .mainCol > .metadata {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 2rem;
  padding: 0 1rem;
}
.UserEmail > .mainCol > .metadata > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}
.UserEmail > .mainCol > .metadata a.userLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.UserEmail > .mainCol > .metadata a.userLink > img {
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
}
.UserEmail > .mainCol > .metadata a.attachment {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  font-size: 80%;
}

.UserEmail > .mainCol > .docs {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 2;
}
.UserEmail > .mainCol > .docs > a.attachmentName {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  border-top: 1px solid var(--light-grey);
  padding: 1rem;
  padding-bottom: 0.5rem;
  font-size: 80%;
  color: var(--dark-grey);
}
.UserEmail > .mainCol > .docs > a.attachmentName .expand {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  transition: transform 0.1s linear;
  transform: rotate(90deg);
  color: var(--grey);
}
.UserEmail > .mainCol > .docs > a.attachmentName .expand.rotated {
  transform: rotate(0deg);
}
.UserEmail > .mainCol > .docs .page {
  border-top: 1px solid var(--light-grey);
  padding: 2rem 1rem;
  background: var(--white);
  display: flex;
  position: relative;
}

.UserEmail > .mainCol > .docs .page > iframe {
  width: 100%;
  flex-shrink: 1;
  background-color: var(--white);
  border: none;
}

.UserEmail > .mainCol > .docs > .downloadLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  border-top: 1px solid var(--light-grey);
  padding: 1rem;
  padding-bottom: 0.5rem;
  font-size: 80%;
}
.UserEmail > .mainCol > .docs > .page.downloadLink > svg {
  font-size: 150%;
}
