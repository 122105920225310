.filterList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px dashed var(--grey);
  border-radius: 1rem;
}

.filterList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px dashed var(--grey);
  border-radius: 1rem;
}

.filterList table {
  margin-bottom: 1rem;
}
.filterList td {
  border: none;
  padding: 0.5rem 0.5rem;
}
.filterList td .inner {
  display: flex;
  flex-direction: row;
  vertical-align: text-top;
  gap: 1rem;
  white-space: nowrap;
}
.filterList td:first-child {
  padding-left: 1rem;
}
.filterList td:last-child {
  padding-right: 1rem;
}
.filterList tr.borderTop > td {
  border-top: 1px dashed var(--grey);
  padding-top: 1rem;
}
.filterList .checkbox {
  width: min-content;
}

.filterList input,
.filterList select {
  padding: 0.25rem 0.5rem;
  margin: 0;
  height: auto;
}
