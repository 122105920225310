.SmallSearch {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  margin-bottom: 1rem;
  min-width: 100%;
}
.SmallSearch input {
  margin-bottom: 0;
}
.SmallSearch .results {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border: 1px solid var(--grey);
  border-radius: 0 0 3px 3px;
  transform: translateY(-1px);
  z-index: 9999;
}
.SmallSearch .results a {
  font-size: 90%;
  font-weight: normal;
  color: var(--dark-grey);
  padding: 0.25rem 1rem;
}
.SmallSearch .results a.selected,
.SmallSearch .results a:hover {
  background-color: var(--light-grey) !important;
}
.SmallSearch .results a:nth-child(2n) {
  background-color: rgb(250, 250, 250);
}
.SmallSearch .inlineLoader {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 99;
}
