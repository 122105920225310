
.QR .qrSpot {
  padding: 2rem;
  background-color: var(--blue);
  border-radius: 1rem;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}
.QR .qrSpot.invert {
  background-color: var(--white);
}
.QR .qrSpot svg {
  width: 15rem;
  height: 15rem;
}
