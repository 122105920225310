.NewBusiness {
  width: 100%;
}

.NewBusiness .titleRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
}
.NewBusiness .titleRow * {
  margin-bottom: 0;
}
