.LogoutOverlay {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.75);
  align-items: center;
  justify-content: center;
}
.LogoutOverlay>div {
  position: absolute;
  width: 30rem;
  border: 1px solid var(--grey);
  background: var(--white);
  border-radius: 4px;
  outline: none;
  padding: 20px;
}
