.EditConstants {
  width: 100%;
}

.EditConstants .wrapper {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-bottom: 1rem;
}

.EditConstants .editor {
  width: calc(100% - 25rem);
  border: 1px solid var(--light-grey);
  border-radius: 2px;
}

.EditConstants .versions {
  display: flex;
  flex-direction: column;
  height: 600px;
  overflow-y: auto;
  flex-grow: 2;
}

.EditConstants .versions .version {
  padding: 0.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid var(--light-grey);
}
.EditConstants .versions .version:hover {
  background-color: rgb(250, 250, 250);
}
.EditConstants .versions .version:last-child {
  border-bottom: 1px solid var(--light-grey);
}
.EditConstants .versions .version .title{
  color: var(--dark-grey);
}
.EditConstants .versions .version .subtitle{
  color: var(--grey);
}
.EditConstants .versions .version.selected {
  cursor: default;
  font-weight: normal;
}
.EditConstants .versions .version.selected:hover {
  background-color: transparent;
}
