.AccountEditList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}
.AccountEditList .Account {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px dashed var(--grey);
  border-radius: 1rem;
}
.AccountEditList .Account .RemoveAccount {
  align-self: flex-end;
  margin-top: 0.5rem;
  margin-right: 1rem;
  height: 2rem;
}

.AccountEditList .Account table {
  margin-bottom: 1rem;
}
.AccountEditList .Account td {
  border: none;
  padding: 0.5rem 0.5rem;
}
.AccountEditList .Account td .inner {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  white-space: nowrap;
}
.AccountEditList .Account td:first-child {
  padding-left: 1rem;
}
.AccountEditList .Account td:last-child {
  padding-right: 1rem;
}
.AccountEditList .Account tr.borderTop>td {
  border-top: 1px dashed var(--grey);
  padding-top: 1rem;
}

.AccountEditList .Account input, .AccountEditList .Account select {
  padding: 0.25rem 0.5rem;
  margin: 0;
  height: auto;
}
