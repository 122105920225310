.UserList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}
.UserList>.user {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  padding: 0.25rem 0;
}
.UserList>a.user:hover .name {
  color: var(--primary-dark);
  text-decoration: underline;
}
.UserList>.user>.avatar {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 2.5rem;
}
.UserList>.user>.name {
  color: var(--dark-grey);
}
.UserList>.user>.tag {
  font-family: 'Courier New', Courier, monospace;
  color: var(--grey);
}
.UserList>.user>.removeButton {
  margin-left: 1rem;
}
