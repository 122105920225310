.ScriptViewModal.modal_overlay {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  padding: 2rem;
  padding-top: 5rem;
  overflow-y: auto;
  --webkit-overflow-scrolling: touch;
  z-index: 20;
}

.ScriptViewModal.modal {
  position: absolute;
  min-width: 30rem;
  border: 1px solid var(--grey);
  background: var(--white);
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

.ScriptViewModal>.button_row {
  display: flex;
  justify-content: space-between;
}

