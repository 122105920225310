.UserChats {
  width: 100%;
}

.UserChats>.backlink {
  font-size: 90%;
  margin-bottom: 1rem;
}

.UserChats .RowIcons {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--dark-grey);
  margin-right: 1rem;
  gap: 1rem;
  font-size: 120%;
}