.MgmtUser {
  width: 100%;
}

.MgmtUser>.red {
  color: var(--red);
}

.MgmtUser>.pretty_box {
  display: flex;
  flex-direction: column;
  border: 1px dashed var(--primary-light);
  background-color: var(--white);
  margin: 2rem 0;
  border-radius: 2px;
  margin-bottom: 3rem;
}
.MgmtUser>.pretty_box>* {
  margin: 0.5rem 1rem;
}
.MgmtUser>.pretty_box b {
  margin-right: 2rem;
}

.MgmtUser>.linklist.vertical {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.MgmtUser>.linklist.vertical>a {
  margin: 0.25rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.MgmtUser>.linklist.vertical>a>.icon {
  margin-right: 1rem;
}
