.BusinessEditor input[type="submit"] {
  margin-top: 2rem;
}

.BusinessEditor .ContactDetails {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px dashed var(--grey);
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
}

.BusinessEditor .ContactDetails label {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  font-weight: normal;
}
.BusinessEditor .ContactDetails label > span:first-child {
  min-width: 8rem;
}

.BusinessEditor .ContactDetails input {
  padding: 0.25rem 0.5rem;
  margin: 0;
  height: auto;
}

.BusinessEditor .UserList {
  margin-bottom: 1rem;
}

.BusinessEditor .ReferralDetails{
  font-weight: bold;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px dashed var(--grey);
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
}

.BusinessEditor .PaymentAction{
  font-weight: bold;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px dashed var(--grey);
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
}

.BusinessEditor .AcceptsDolla{
  font-weight: bold;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px dashed var(--grey);
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
}
