.Loader {
  margin: 3rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Loader.small {
  margin: 0.25rem;
  width: 1.5rem;
  height: 1.5rem;
}
.Loader.small > div {
  width: 1.5rem !important;
  height: 1.5rem !important;
  margin-top: -9px;
}
.Loader.medium {
  margin: 0.25rem;
  scale: 0.5;
}
.Loader.small.inline {
  max-height: 2rem;
}
