.EmailTemplates {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 1rem;
}

.EmailTemplates .sideBar {
  display: flex;
  flex-direction: column;
}
.EmailTemplates .sideBar a {
  padding: 0.5rem 1rem;
  color: var(--dark-grey);
  border: 1px solid var(--grey);
  border-bottom: none;
  border-radius: 3px;
  transition: all 0.2s linear;
}
.EmailTemplates .sideBar a.selected {
  background-color: var(--light-grey);
}
.EmailTemplates .sideBar a:last-child {
  border-bottom: 1px solid var(--grey);
}
.EmailTemplates .sideBar a:hover {
  background-color: var(--light-grey);
}

.EmailTemplates form {
  padding: 1rem;
  flex-grow: 2;
}
.EmailTemplates form .formTable {
  padding: 1rem;
  border: 1px solid var(--light-grey);
  border-radius: 3px;
  margin-bottom: 1rem;
}
.EmailTemplates form .formTable td {
  border: none;
  padding: 0.5rem 1rem;
}
.EmailTemplates form .formTable td:first-child {
  text-align: right;
  width: 0;
  white-space: nowrap;
}
.EmailTemplates form .formTable td input {
  margin-bottom: 0;
}
.EmailTemplates form .buttonRow {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 2rem;
  gap: 1rem;
}
.EmailTemplates form .buttonRow>* {
  margin-bottom: 0;
  flex-grow: 2;
}
.EmailTemplates form .buttonRow .delete {
  background-color: var(--red);
  border-color: var(--red);
  flex-grow: 0;
}
.EmailTemplates form .buttonRow .delete:hover {
  opacity: 0.8;
}
.EmailTemplates form .expandIcon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  margin-top: 0.5rem;
  transition: transform 0.2s linear;
}
.EmailTemplates form .expandIcon.collapsed {
  transform: rotate(-90deg);
}
.EmailTemplates form .expandIcon.expanded {
  transform: rotate(0deg);
}
.EmailTemplates form .editor {
  border: 1px solid var(--light-grey);
  border-radius: 3px;
  margin-bottom: 1rem;
  transition: all 0.25s linear;
  overflow: hidden;
}
.EmailTemplates form .editor.expanded {
  height: 300px
}
.EmailTemplates form .editor.large.expanded {
  height: 600px
}
.EmailTemplates form .editor.collapsed {
  border-color: transparent;
  height: 0px;
}
.EmailTemplates iframe {
  border: none;
  width: 100%;
}
