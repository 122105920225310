.AccountModal.modal_overlay {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  padding: 2rem;
  padding-top: 5rem;
  overflow-y: auto;
  --webkit-overflow-scrolling: touch;
  z-index: 20;
}

.AccountModal.modal {
  position: absolute;
  min-width: 30rem;
  border: 1px solid var(--grey);
  background: var(--white);
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

.AccountModal.modal .wide {
  width: 100%;
}
.AccountModal.modal.compact {
  max-width: 80%;
}

.AccountModal .red {
  color: var(--red);
}

.AccountModal .buttonRow {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding-top: 2rem;
}
.AccountModal button.red {
  color: var(--white);
  background-color: var(--red-dark);
  border-color: var(--red-dark);
}
.AccountModal button.red:hover {
  color: var(--white);
  background-color: var(--red-dark);
  border-color: var(--red-dark);
  opacity: 0.8;
}

.AccountModal b {
  font-weight: normal;
}
.AccountModal .attributeList {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
}
.AccountModal .attributeList > span {
  font-size: 80%;
  font-weight: normal;
  padding: 0.5rem;
  color: var(--white);
  background-color: var(--primary-light);
  border-radius: 3px;
}