.UserDetails {
  border: 1px dashed var(--primary-light);
  border-radius: 0.5rem;
  width: auto;
  margin-bottom: 1rem;
}
.UserDetails td {
  padding: 0.25rem 1.5rem;
  padding-right: 3rem;
  border: none;
  vertical-align: top;
}
.UserDetails td:last-child {
  padding-right: 1.5rem;
}
.UserDetails td:first-child {
  text-align: right;
}
.UserDetails tr:last-child td {
  padding-bottom: 1rem;
}
.UserDetails td span {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.UserDetails input, .UserDetails select {
  margin-bottom: 0;
  padding: 0.25rem 1rem;
  height: auto;
  font-size: 90%;
}
.UserDetails .buttonRow {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 1rem;
  flex-wrap: wrap
}
.UserDetails .buttonRow button {
  width: 100%;
}
