.ChatAvatarImage {
  width: 4rem;
  height: 4rem;
  border-radius: 4rem;
  margin-right: 1rem;
  border: 1px solid var(--light-grey);
}

.ChatAvatarStack {
  width: calc(4rem + 2px);
  height: calc(4rem + 2px);
  margin-right: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.ChatAvatarStack .ChatAvatarImage {
  width: 3rem;
  height: 3rem;
  border-radius: 3rem;
  border: 1px solid var(--light-grey);
  margin-right: 0;
  margin-left: -2rem;
}
.ChatAvatarStack .ChatAvatarImage:first-child {

  margin-left: 0
}
.ChatAvatarStack .extraPeople {
  min-width: 1.75rem;
  height: 1.75rem;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3rem;
  font-size: 60%;
  background-color: var(--primary);
  color: var(--dark-grey);
  align-self: flex-end;
  /* margin-bottom: 0.5rem; */
  margin-left: -1.75rem;
}
