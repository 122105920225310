.UserPayments {
  width: 100%;
}
.UserPayments>.backlink {
  font-size: 90%;
  margin-bottom: 1rem;
}
.UserPayments > .linkRow {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-bottom: 2rem;
  font-size: 90%;
}
.UserPayments>.payments>.date {
  background-color: var(--light-grey);
  font-size: 80%;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.UserPayments>.payments>.date>.subtitle {
  color: var(--grey);
}
