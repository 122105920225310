.EntityDetailEditList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}
.EntityDetailEditList > a {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.EntityDetailEditList > .ContactDetails > span {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.EntityDetailEditList .Account {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px dashed var(--grey);
  border-radius: 1rem;
}

.EntityDetailEditList .Account .RemoveAccount {
  align-self: flex-end;
  margin-top: 0.5rem;
  margin-right: 1rem;
  height: 2rem;
}

.EntityDetailEditList .Account .RemoveAccount > span {
  color: var(--red);
  margin-right: 1rem;
  font-weight: 300;
}

.EntityDetailEditList .Account table {
  margin-bottom: 1rem;
}
.EntityDetailEditList .Account td {
  border: none;
  padding: 0.5rem 0.5rem;
}
.EntityDetailEditList .Account td .inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  white-space: nowrap;
}
.EntityDetailEditList .Account td:first-child {
  padding-left: 1rem;
}
.EntityDetailEditList .Account td:last-child {
  padding-right: 1rem;
}
.EntityDetailEditList .Account tr.borderTop > td {
  border-top: 1px dashed var(--grey);
  padding-top: 1rem;
}

.EntityDetailEditList .Account input,
.EntityDetailEditList .Account select {
  padding: 0.25rem 0.5rem;
  margin: 0;
  height: auto;
}

.EntityDetailEditList .ContactDetails {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border: 1px dashed var(--grey);
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
}

.EntityDetailEditList .avatar {
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
}

.EntityDetailEditList .description {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  align-items: center;
  font-weight: 400;
}

.EntityDetailEditList .description > a {
  display: flex;
  align-items: center;
}

.EntityDetailEditList .ContactDetails label {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1rem;
  font-weight: normal;
}
.EntityDetailEditList .ContactDetails label > span:first-child {
  min-width: 8rem;
}

.EntityDetailEditList .ContactDetails input {
  padding: 0.25rem 0.5rem;
  margin: 0;
  height: auto;
}

.EntityDetailEditList .field {
  display: flex;
  gap: 1rem;
}

.EntityDetailEditList .AddressList {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

.EntityDetailEditList a {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.EntityDetailEditList a.addField {
  display: flex;
  align-self: flex-end;
  margin-bottom: 1rem;
  font-weight: 300;
}
