.NZBNEntitySearch {
  width: inherit;
}

.NZBNEntitySearch .results a {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  min-width: 100%;
}
.NZBNEntitySearch .results a .result .avatar {
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 2rem;
  margin-left: 0;
  margin-right: 0.5rem;
}

.NZBNEntitySearch .result {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}

.NZBNEntitySearch .badge {
  z-index: 99;
  top: 0.2rem;
  right: 0.2rem;
  background-color: var(--blue);
  color: var(--white);
  border-radius: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.NZBNEntitySearch .subtitle {
  font-size: 1.2rem;
}
