.EntitySelector {
  display: flex;
  flex-direction: column;
  position: relative;
}
.EntitySelector .inlineLoader {
  display: flex;
  z-index: 99;
}

.EntitySelector .loader {
  align-items: center;
  justify-content: center;
  outline: 1px;
  outline-style: dashed;
  border-radius: 2px;
  padding: 1rem;
}

.EntitySelector .badge {
  position: absolute;
  z-index: 99;
  top: 0.2rem;
  right: 0.2rem;
  background-color: var(--blue);
  color: var(--white);
  border-radius: 2rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.EntitySelector .warning {
  background-color: var(--red);
}

.EntitySelector .accountNumber {
  font-size: 80%;
}

.EntitySelector .avatar {
  width: 3rem;
  height: 3rem;
  border-radius: 3rem;
  margin-left: 0.5rem;
}

.EntitySelector .descriptionWrapper {
  margin-bottom: 1.5rem;
}

.EntitySelector .description {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.4rem;
  font-size: 1.2rem;
  align-items: center;
}

.EntitySelector .entityFields {
  /* max-height: 40rem; */
  overflow-y: scroll;
  outline: 1px;
  outline-style: dashed;
  border-radius: 2px;
  padding: 1rem;
}

.EntitySelector .noteWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.EntitySelector .note {
  font-size: 1.2rem;
  font-weight: 400;
  margin-left: 0.2rem;
  margin-bottom: 0;
}

.EntitySelector label {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
