.UserContacts {
  width: 100%;
}
.UserContacts > .backlink {
  font-size: 90%;
  margin-bottom: 1rem;
}
.UserContacts .contactTitle > .tag {
  font-family: monospace;
  margin-left: 1.5rem;
  margin-top: 0.5rem;
  color: var(--dark-grey);
  background-color: var(--light-grey);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-size: 70%;
}
.UserContacts .ArrowForward {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--dark-grey);
  margin-right: 1rem;
  font-size: 120%;
}
.UserContacts .Status {
  margin-right: 1rem;
}
