.Alert.Error {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  color: rgb(195, 52, 71);
  border: 1px solid rgb(195, 52, 71);
  border-radius: 4px;
  background-color: rgb(252, 228, 232);
  padding: 0.5rem 1rem;
  margin-bottom: 2rem;
}
.Alert.Info {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  color: rgb(52, 161, 195);
  border: 1px solid rgb(52, 161, 195);
  border-radius: 4px;
  background-color: rgb(228, 249, 252);
  padding: 0.5rem 1rem;
  margin-bottom: 2rem;
}

.Alert.Popup {
  position: fixed;
  font-size: 1.5rem;
  bottom: 1rem;
  right: 1rem;
  z-index: 999;
  align-items: center;
}
