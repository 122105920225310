.WebhookStatus {
  flex: 1,1, auto;
  flex-direction: row;
}

.WebhookStatus .active {
  color: var(--green);
}

.WebhookStatus  .loading {
  color: var(--grey);
}

.WebhookStatus .inactive {
  color: var(--red);
}

.WebhookStatus .refresh {
  color: var(--blue);
}

.WebhookStatus .spin {
  animation: spin 1s linear infinite;
  transform-origin: center;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  } 
  to {
    transform: rotate(360deg);
  }
}
