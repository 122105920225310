.SearchUsersPage {
  width: 100%;
}

.SearchUsersPage .titleRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
}
.SearchUsersPage .titleRow * {
  margin-bottom: 0;
}
.SearchUsersPage .userCounts {
  margin-bottom: 1rem;
}
.SearchUsersPage .filtersWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.SearchUsersPage .filtersWrapper .buttonRow {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.SearchUsersPage .filtersWrapper > button {
  width: max-content;
}

.SearchUsersPage .statusIcon {
  font-size: 120%;
  margin-left: 1rem;
}

.SearchUsersPage .statusIcon.green {
  color: var(--green);
}
.SearchUsersPage .statusIcon.blue {
  color: var(--blue);
}
.SearchUsersPage .statusIcon.red {
  color: var(--red);
}
.SearchUsersPage .statusIcon.orange {
  color: var(--orange);
}

.SearchUsersPage .List {
  border-top: 1px solid var(--light-grey);
}
.SearchUsersPage .List>.row:first-child {
  border-top: none;
}
