.DollaChat {
  width: 100%;
}
.DollaChat>.backlink {
  font-size: 90%;
  margin-bottom: 1rem;
}

.DollaChat>.ChatWidget {
  border-radius: 1rem;
  border: 1px solid var(--grey);
}

.DollaChat>.ChatWidget>.MessageInput {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 1rem;
  background-color: var(--light-grey);
  border-radius: 0 0 1rem 1rem;
  margin-bottom: 0;
  padding: 1rem;
}

.DollaChat>.ChatWidget>.MessageInput>textarea {
  margin: 0;
  resize: none;
}
.DollaChat>.ChatWidget>.MessageInput>button {
  height: initial;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
.DollaChat>.ChatWidget>.MessageInput>button>img {
  width: 3rem;
  border-radius: 1.5rem;
}
