.Wrapper {
  background-color: #f5f5f5ff;
  background-image: url("data:image/svg+xml,%3Csvg width='64' height='64' viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 16c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm0-2c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6zm33.414-6l5.95-5.95L45.95.636 40 6.586 34.05.636 32.636 2.05 38.586 8l-5.95 5.95 1.414 1.414L40 9.414l5.95 5.95 1.414-1.414L41.414 8zM40 48c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm0-2c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6zM9.414 40l5.95-5.95-1.414-1.414L8 38.586l-5.95-5.95L.636 34.05 6.586 40l-5.95 5.95 1.414 1.414L8 41.414l5.95 5.95 1.414-1.414L9.414 40z' fill='%23f0f0f0' fill-opacity='1' fill-rule='evenodd'/%3E%3C/svg%3E");
}

/* Header */
.Wrapper > header {
  background-color: var(--header-black);
  color: var(--white);
  padding: 1rem 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.Wrapper > header > nav {
  width: 100%;
  max-width: 120rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Wrapper > header > nav .logo {
  width: 3.5rem;
  min-width: 3.5rem;
  height: 3.5rem;
  min-height: 3.5rem;
  margin-top: 0.75rem;
  border-radius: 3.5rem;
}
.Wrapper > header > nav .links {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.Wrapper > header > nav .roundIcon {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px rgba(255, 255, 255, 0.8) solid;
  border-radius: 100%;
  padding: 0.1rem;
  background: transparent;
  color: var(--white);
  margin-bottom: 0;
}
.Wrapper > header > nav .roundIcon:hover {
  color: var(--primary);
}
.Wrapper > header > nav .WrapperTippy {
  background-color: var(--white);
  border: 1px solid var(--grey);
}
.Wrapper > header > nav .WrapperTippy .tippy-arrow {
  color: var(--white);
}
.Wrapper > header > nav .WrapperTippy .tippy-content {
  padding: 1rem 0;
}
.Wrapper > header > nav .WrapperTippy .tippy-content div {
  display: flex;
  flex-direction: column;
}
.Wrapper > header > nav .WrapperTippy .tippyLink {
  padding: 0.5rem 1rem;
  width: 100%;
  color: var(--black);
  cursor: pointer;
}
.Wrapper > header > nav .WrapperTippy .tippyLink:hover {
  background-color: var(--light-grey);
  border-radius: 3px;
}

/* Content */
.Wrapper > section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.Wrapper > section > .content {
  max-width: 120rem;
  width: 120rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem;
  min-height: 100vh;
}
.Wrapper > section > .content.wide {
  max-width: 100%;
  width: 100%;
}

/* Footer */
.Wrapper > footer {
  font-size: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: var(--grey);
  padding: 1.5rem;
  width: 100%;
}
