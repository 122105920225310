.EntityEditor input[type="submit"] {
  margin-top: 2rem;
}

.EntityEditor > label {
  position: relative;
}

.EntityEditor .ContactDetails {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px dashed var(--grey);
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
}

.EntityEditor .avatar {
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
}

.EntityEditor .description {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  align-items: center;
  font-weight: 400;
}

.EntityEditor .description > a {
  display: flex;
  align-items: center;
}

.EntityEditor .ContactDetails label {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  font-weight: normal;
}
.EntityEditor .ContactDetails label > span:first-child {
  min-width: 8rem;
}

.EntityEditor .ContactDetails input {
  padding: 0.25rem 0.5rem;
  margin: 0;
  height: auto;
}

.EntityEditor .UserList {
  margin-bottom: 1rem;
}

.EntityEditor .ReferralDetails {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px dashed var(--grey);
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
}

.EntityEditor .PaymentAction {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px dashed var(--grey);
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
}

.EntityEditor .AcceptsDolla {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px dashed var(--grey);
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
}

.EntityEditor .AddressList {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

.EntityEditor .inlineLoader {
  position: absolute;
  align-items: center;
  top: 45%;
  right: 1rem;
  z-index: 99;
}

.EntityEditor .Verify {
  display: flex;
  gap: 3rem;
}

.EntityEditor .Verify > div {
  display: flex;
  gap: 1rem;
  text-wrap: nowrap;
  align-items: center;
}
