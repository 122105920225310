.UniversalPage {
  font-family: monospace;
  font-weight: lighter;
}

.UniversalPage > h3 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.UniversalPage > a.titleLink {
  color: var(--blue);
  font-size: 75%;
  padding: 0 0.5rem;
}

.UniversalPage .load_line {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  max-height: 2rem;
}
.UniversalPage .mini_loader {
  margin-top: 0.75rem;
  margin-left: 0.5rem;
}
.UniversalPage .key {
  font-weight: bold;
}
.UniversalPage .children {
  display: block;
}
.UniversalPage .children > div {
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
}
.UniversalPage .long_line {
  word-break: break-all;
}
.UniversalPage .buttonRow {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
