.EntityAccountEditList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
  align-items: flex-end;
}
.EntityAccountEditList > a {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
}

.EntityAccountEditList > label {
  padding: 1rem;
}
.EntityAccountEditList .Account {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px dashed var(--grey);
  border-radius: 1rem;
}

.EntityAccountEditList .Account .RemoveAccount {
  align-self: flex-end;
  margin-top: 0.5rem;
  margin-right: 1rem;
  height: 2rem;
}

.EntityAccountEditList .Account .RemoveAccount > span {
  color: var(--red);
  margin-right: 1rem;
  font-weight: 300;
}

.EntityAccountEditList .Account table {
  margin-bottom: 1rem;
}
.EntityAccountEditList .Account td {
  border: none;
  padding: 0.5rem 0.5rem;
}
.EntityAccountEditList .Account td .inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  white-space: nowrap;
}
.EntityAccountEditList .Account td:first-child {
  padding-left: 1rem;
}
.EntityAccountEditList .Account td:last-child {
  padding-right: 1rem;
}
.EntityAccountEditList .Account tr.borderTop > td {
  border-top: 1px dashed var(--grey);
  padding-top: 1rem;
}

.EntityAccountEditList .Account input,
.EntityAccountEditList .Account select {
  padding: 0.25rem 0.5rem;
  margin: 0;
  height: auto;
}

.EntityAccountEditList .inner span {
  font-size: 14px;
  color: var(--red);
}
