
.TippyExplainer {
  background-color: var(--white);
  border: 1px solid var(--grey);
  color: var(--dark-grey);
}
.TippyExplainer .tippy-arrow::before {
  color: var(--grey);
}
.TippyExplainer .tippy-content {
  padding: 1rem;
}
.TippyExplainer .tippy-content div {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.TippyExplainer .tippy-content div.row {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  padding: 0 1rem;
}

.TippyExplainerTrigger {
  color: var(--primary-dark);
  opacity: 0.5;
  display: inline-flex;
  width: 1.75rem;
  height: 1.75rem;
  align-items: center;
  justify-content: center;
  vertical-align: text-bottom;
}
.TippyExplainerTrigger:hover {
  color: var(--primary-dark);
  cursor: default;
}
