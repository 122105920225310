.SearchBusinessesPage {
  width: 100%;
}

.SearchBusinessesPage .titleRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
}
.SearchBusinessesPage .titleRow * {
  margin-bottom: 0;
}
.SearchBusinessesPage .visibilitySelector {
  width: auto;
}
.SearchBusinessesPage .statusIcon {
  font-size: 120%;
  margin-left: 1rem;
}
.SearchBusinessesPage .statusIcon.green {
  color: var(--green);
}
.SearchBusinessesPage .statusIcon.blue {
  color: var(--blue);
}
.SearchBusinessesPage .statusIcon.red {
  color: var(--red);
}
.SearchBusinessesPage .statusIcon.orange {
  color: var(--orange);
}
