.UserIdentities {
  width: 100%;
}

.UserIdentities > .backlink {
  font-size: 90%;
  margin-bottom: 1rem;
}

.UserIdentities .attributeList {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
}
.UserIdentities .attributeList > span {
  font-size: 80%;
  font-weight: normal;
  padding: 0.5rem;
  color: var(--white);
  background-color: var(--primary-light);
  border-radius: 3px;
}
